<template>
  <div class="bbr-trainer--list">
    <v-simple-table>
      <thead>
        <tr>
          <th>Trainer Name</th>
          <th>Trainer Description</th>
          <th>Trainer Image</th>
          <th>Trainer Share Image</th>
        </tr>
      </thead>

      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
        infinite-scroll-distance="50"
      >
        <template>
          <tr
            tag="tr"
            v-for="trainer in list"
            :key="trainer.id"
            class="clickable new-tab-link-row"
          >
            <td v-list-link="setListLink(trainer.id)" width="20%">
              {{ trainer.full_name }}
            </td>
            <td v-list-link="setListLink(trainer.id)">
              {{ trainer.description }}
            </td>
            <td v-list-link="setListLink(trainer.id)" width="20%">
              {{ trainer.image }}
            </td>
            <td v-list-link="setListLink(trainer.id)" width="20%">
              {{ trainer.share_image }}
            </td>
          </tr>
        </template>
      </tbody>

      <table-skeleton-loader v-if="isLoading" column="3" row />
    </v-simple-table>

    <no-list
      v-if="!hasTrainersList && !isLoading"
      details="No Available Trainers"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

import { mapGetters } from 'vuex'

export default {
  name: 'TrainerListTable',

  directives: {
    infiniteScroll,
  },

  components: {
    TableSkeletonLoader,
    NoList,
  },

  computed: {
    ...mapGetters({
      trainers: 'trainers/trainers',
      isLoading: 'trainers/isLoading',
      hasTrainersList: 'trainers/hasTrainersList',
    }),

    list() {
      return this.trainers.list
    },

    meta() {
      return this.trainers.meta
    },

    lastPage() {
      return !this.meta.to
    },

    disableListScroll() {
      return this.isLoading || this.lastPage
    },
  },

  methods: {
    loadMore() {
      if (this.disableListScroll) {
      } else {
        this.$emit('load', this.meta.current_page + 1)
      }
    },

    setListLink(trainerId) {
      return {
        id: trainerId,
        routeName: 'trainer.info',
        fullPath: `/trainers/${trainerId}/details`,
      }
    },
  },
}
</script>
