<template>
  <div class="bbr-trainers">
    <main-app-bar>
      <template v-slot:title> Trainers </template>

      <template v-slot:top-actions>
        <v-btn
          :to="{ name: 'new.trainer' }"
          class="bg-primary-gradient primary ml-auto"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Trainer
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <v-row>
        <v-col cols="12">
          <div class="form-container d-flex align-center">
            <div class="search-form">
              <v-text-field
                data-testid="trainer-search-input"
                label="Search Trainer"
                style="width:400px"
                @input="onTrainerSearch"
                hide-details
                clearable
                rounded
                solo
                flat
              />
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <trainer-list @load="loadMoreList" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import TrainerList from '../components/TrainerList'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'Trainers',

  components: {
    TrainerList,
    MainAppBar,
  },

  data() {
    return {
      search: null,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'trainers/isLoading',
      hasTrainersList: 'trainers/hasTrainersList',
    }),
  },

  mounted() {
    if (!this.hasTrainersList) {
      this.fetchTrainers()
    }
  },

  methods: {
    ...mapActions({
      fetchTrainersList: 'trainers/getTrainers',
    }),

    ...mapMutations({
      clearTrainerList: 'trainers/clearTrainerList',
    }),

    loadMoreList(page = 1) {
      this.fetchTrainers(page)
    },

    fetchTrainers(page = 1) {
      this.fetchTrainersList({ search: this.search, limit: 50, page })
    },

    onTrainerSearch(term) {
      this.search = term
      this.searchOnTrainers()
    },

    searchOnTrainers: debounce(function() {
      this.clearTrainerList()
      this.fetchTrainers()
    }, 600),
  },
}
</script>
